import './index.scss';
import AppLoader from "./AppLoader";
import init from './page/enterprise/init';

const loader = new AppLoader({
    'index': {
        load: () => import('./page/index/IndexPage'),
        headers: {
            'X-Tldc-Source-Tenant': 'STARCLOUD'
        }
    },
    'enterprise': {
        load: () => import('./page/enterprise/IndexPage'),
        init: init
    },
});

loader.load();
