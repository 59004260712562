import {post, route} from '@alipay/isportal-app-web-base';


const env = window.env === '${env}' ? 'local' : window.env;
const variables = {
    loginUser: {
        userId: null,
        busvcNickName: '<未登录>',
        tntInstId: null
    }
}

function initLoginUser(hosts) {
    return new Promise((resolve) => {
        const uri = route(hosts, env, '/', '/enterprise/user/getLoginContext.json');
        post(uri, {
            always: resp => {
                if (resp.success && resp.data) {
                    variables.loginUser = {
                        userId: resp.data.userId,
                        busvcNickName: resp.data.userName,
                        tntInstId: resp.data.tntInstId
                    };
                }
                resolve();
            }
        })
    })
}

function getLoginUser() {
    return variables.loginUser;
}

function getLoginUserTenants() {
    return variables.loginUser.tntInstId ? [{
        tntInstId: variables.loginUser.tntInstId,
        tntInstName: variables.loginUser.tntInstId,
        current: true
    }] : []
}

export default function init(hosts, headers, resolve, reject) {
    Promise.all([initLoginUser(hosts)])
        .then(() => {
            resolve({
                env,
                getLoginUser,
                getLoginUserTenants,
                isCloudInnerEnv: () => true
            })
        })
        .catch(reject);
}